import styled from 'styled-components'

import { Container, Text } from 'common/UI'
import { TextHighlightStoryblok } from 'common/types'
import { hasValidLink, textByLine } from 'common/utils/content'
import { CMSLink } from 'modules/shared'

type Props = {
  block: TextHighlightStoryblok
}

export const TextHighlight = ({ block, ...props }: Props): JSX.Element => {
  const { text, align, button_label, button_link } = block

  return (
    <Container variant="typography" {...props}>
      <Wrapper>
        {text &&
          textByLine(text, (part) => {
            return (
              <TextLine
                as="p"
                variant="title/medium"
                css={{ textAlign: align }}
              >
                {part}
              </TextLine>
            )
          })}
        {button_label && button_link && hasValidLink(button_link) && (
          <ButtonWrapper>
            <CMSLink
              href={button_link}
              variant="ghost"
              css={{ marginTop: '2rem' }}
            >
              {button_label}
            </CMSLink>
          </ButtonWrapper>
        )}
      </Wrapper>
    </Container>
  )
}

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 55rem;
  padding: 1.5rem 0;

  ${({ theme }) => theme.media.md} {
    padding: 2.5rem 0;
  }
`

const TextLine = styled(Text)`
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
`
